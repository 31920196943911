<template>
  <div class="bgwrap" @click="$parent.show_left_nav = false">
    <div
      class="left_nav"
      @click.stop=" a = 1"
    >
      <!--游戏类型-->
      <div class="game_type">
        <div
          class="game_item_left"
          :class="active_menu==0?'active':''"
          @click="goMenu(0)"
        >
          <img
            src="../assets/images/common/02.png"
            class="game_img"
            alt=""
          >
          <div class="game_name">
            Popular
          </div>
        </div>
        <div
          class="game_item_left"
          :class="active_menu==1?'active':''"
          @click="goMenu(1)"
        >
          <img
            src="../assets/images/common/04.png"
            class="game_img"
            alt=""
          >
          <div class="game_name">
            Slot
          </div>
        </div>
        <div
          class="game_item_left"
          :class="active_menu==2?'active':''"
          @click="goMenu(2)"
        >
          <img
            src="../assets/images/common/06.png"
            class="game_img"
            alt=""
          >
          <div class="game_name">
            Blockchain
          </div>
        </div>
        <div
          class="game_item_left"
          :class="active_menu==3?'active':''"
          @click="goMenu(3)"
        >
          <img
            src="../assets/images/common/08.png"
            class="game_img"
            alt=""
          >
          <div class="game_name">
            Recentes
          </div>
        </div>
          <div
              class="game_item_left"
              :class="active_menu==4?'active':''"
              @click="goMenu(4)"
          >
              <img
                  src="../assets/images/common/10.png"
                  class="game_img"
                  alt=""
              >
              <div class="game_name">
                  Melhor jogo
              </div>
          </div>
      </div>


      <!-- MP3播放器-->
      <div class="mp3_player">
        <audio-player
          ref="audioPlayer"
          :audio-list="audioList.map(elm => elm.url)"
          :before-play="handleBeforePlay"
          :show-progress-bar="false"
          :show-playback-rate="false"
          theme-color="#feb705"
        />
        <div class="music_name">
          {{ currentAudioName || audioList[0].name }}
        </div>
      </div>


      <div
        class="game_record"
        @click="$router.push('gameRecord')"
      >
        <div class="grsvg">
          <svg
            width="80px"
            height="80px"
            viewBox="0 -8.5 1041 1041"
            class="icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M55.634646 16.125984h630.525984C707.930709 16.125984 725.669291 33.864567 725.669291 55.634646v912.730708c0 21.770079-17.738583 39.508661-39.508661 39.508662H55.634646c-21.770079 0-39.508661-17.738583-39.508662-39.508662V55.634646C16.125984 33.864567 33.864567 16.125984 55.634646 16.125984z"
              fill="#FFFFFF"
            />
            <path
              d="M686.16063 1024H55.634646c-30.63937 0-55.634646-24.995276-55.634646-55.634646V55.634646C0 24.995276 24.995276 0 55.634646 0h630.525984C716.8 0 741.795276 24.995276 741.795276 55.634646v912.730708c0 30.63937-24.995276 55.634646-55.634646 55.634646zM55.634646 32.251969C42.733858 32.251969 32.251969 42.733858 32.251969 55.634646v912.730708c0 12.900787 10.48189 23.382677 23.382677 23.382677h630.525984c12.900787 0 23.382677-10.48189 23.382677-23.382677V55.634646C709.543307 42.733858 699.061417 32.251969 686.16063 32.251969H55.634646z"
              fill=""
            />
            <path
              d="M275.754331 866.771654c-4.031496 0-8.062992-1.612598-11.288189-4.837796L171.741732 770.015748c-6.450394-6.450394-6.450394-16.125984 0-22.576378 6.450394-6.450394 16.125984-6.450394 22.576378 0l92.72441 92.72441c6.450394 6.450394 6.450394 16.125984 0 22.576377-3.225197 2.418898-7.256693 4.031496-11.288189 4.031497z"
              fill=""
            />
            <path
              d="M181.417323 869.190551c-4.031496 0-8.062992-1.612598-11.288189-4.837795-6.450394-6.450394-6.450394-16.125984 0-22.576378l96.755905-96.755906c6.450394-6.450394 16.125984-6.450394 22.576378 0 6.450394 6.450394 6.450394 16.125984 0 22.576378l-96.755905 96.755906c-3.225197 3.225197-7.256693 4.837795-11.288189 4.837795z"
              fill=""
            />
            <path
              d="M420.08189 721.637795l541.833071-541.833071c8.869291-7.256693 22.576378-6.450394 29.83307 2.418898l26.607875 26.607874c8.869291 7.256693 9.675591 20.96378 2.418897 29.833071L478.941732 780.497638l-83.855118 24.188976 24.995276-83.048819z"
              fill="#7BAEEF"
            />
            <path
              d="M395.086614 820.812598c-4.031496 0-8.062992-1.612598-11.288189-4.837795-4.031496-4.031496-5.644094-10.48189-4.031496-16.125984l24.995276-83.048819c0.806299-2.418898 2.418898-4.837795 4.031496-6.450394l541.833071-541.833071 0.806299-0.806299c15.319685-12.900787 38.702362-10.48189 51.603149 4.031496l25.801575 25.801575c14.513386 12.900787 16.932283 36.283465 4.031496 51.60315 0 0.806299-0.806299 0.806299-0.806299 0.806299L490.229921 791.785827c-1.612598 1.612598-4.031496 3.225197-7.256693 4.031496l-83.855118 24.188976c-0.806299 0.806299-2.418898 0.806299-4.031496 0.806299z m39.508662-91.111811L419.275591 781.303937l51.603149-15.319685 538.607874-538.607874c1.612598-2.418898 0.806299-4.837795-0.806299-7.256693l-0.806299-0.806299-26.607874-26.607874-0.806299-0.806299c-1.612598-1.612598-4.837795-2.418898-7.256693-0.8063L434.595276 729.700787z"
              fill=""
            />
            <path
              d="M838.551181 528.932283c-4.031496 0-8.062992-1.612598-11.288189-4.837795-6.450394-6.450394-6.450394-16.125984 0-22.576378l145.133858-145.133858L878.866142 262.853543c-6.450394-6.450394-6.450394-16.125984 0-22.576378 6.450394-6.450394 16.125984-6.450394 22.576378 0l105.625197 105.625197c3.225197 3.225197 4.837795 7.256693 4.837795 11.288189 0 4.031496-1.612598 8.062992-4.837795 11.288189l-156.422048 156.422047c-3.225197 3.225197-8.062992 4.031496-12.094488 4.031496zM588.598425 191.092913H159.647244c-8.869291 0-16.125984-7.256693-16.125984-16.125984s7.256693-16.125984 16.125984-16.125984H588.598425c8.869291 0 16.125984 7.256693 16.125984 16.125984s-7.256693 16.125984-16.125984 16.125984zM588.598425 283.817323H159.647244c-8.869291 0-16.125984-7.256693-16.125984-16.125984s7.256693-16.125984 16.125984-16.125985H588.598425c8.869291 0 16.125984 7.256693 16.125984 16.125985s-7.256693 16.125984-16.125984 16.125984zM588.598425 380.573228H159.647244c-8.869291 0-16.125984-7.256693-16.125984-16.125984s7.256693-16.125984 16.125984-16.125984H588.598425c8.869291 0 16.125984 7.256693 16.125984 16.125984s-7.256693 16.125984-16.125984 16.125984z"
              fill=""
            />
            <path
              d="M588.598425 473.297638H159.647244c-8.869291 0-16.125984-7.256693-16.125984-16.125984s7.256693-16.125984 16.125984-16.125985H588.598425c8.869291 0 16.125984 7.256693 16.125984 16.125985s-7.256693 16.125984-16.125984 16.125984z"
              fill=""
            />
            <path
              d="M356.384252 577.310236H159.647244c-8.869291 0-16.125984-7.256693-16.125984-16.125984s7.256693-16.125984 16.125984-16.125984h195.930709c8.869291 0 16.125984 7.256693 16.125984 16.125984s-6.450394 16.125984-15.319685 16.125984z"
              fill=""
            />
          </svg>
        </div>
        <div class="grt">
          Recordes de Apostas
        </div>
      </div>

      <div class="active_record">
        <div
          class="active_item acbg1"
          @click="$router.push('person')"
        >
           Perfil
        </div>
        <div
          class="active_item acbg2"
          @click="$router.push('betReward')"
        >
          Rebate
        </div>
        <div
          class="active_item acbg3"
          @click="$router.push('recharge')"
        >
          Depósito
        </div>
        <div
          class="active_item acbg4"
          @click="$router.push('withdraw')"
        >
          Saque
        </div>
        <div
          class="active_item acbg5"
          @click="$router.push('agentData')"
        >
          Meus Indicados
        </div>
        <div
          class="active_item acbg6"
          @click="$router.push('vip')"
        >
          VIP
        </div>
          <div
              class="active_item acbg7"
              @click="$router.push('share')"
          >
              Promoção
          </div>
      </div>


      <div class="other_list">
        <a
          :href="$store.state.config.download_url"
          target="_blank"
          class="other_item"
        >
          <div class="icon-wrap">
            <van-icon name="down" />
          </div>
          <div class="other_text">Baixar App</div>
        </a>
        <div
          class="other_item"
          @click="goSupport"
        >
          <div class="icon-wrap">
            <van-icon name="service-o" />
          </div>
          <div class="other_text">
            Suporte
          </div>
        </div>
        <div
          class="other_item"
          @click="goSupport"
        >
          <div class="icon-wrap">
            <van-icon name="question-o" />
          </div>
          <div class="other_text">
            FAQ
          </div>
        </div>
        <div
          class="other_item"
          @click="logout()"
        >
          <div class="icon-wrap">
            <van-icon name="stop-circle-o" />
          </div>
          <div class="other_text">
            Sair
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Fetch from '../utils/fetch';
import AudioPlayer from '@liripeng/vue-audio-player'

import {
    Icon,
    Cell,
    CellGroup
} from "vant";

Vue.use(Cell).use(CellGroup).use(Icon);

export default {
    name: "User",
    components: {
        AudioPlayer
    },
    data() {
        return {
            currentAudioName: '',
            active_menu:0,
            audioList: [
                // {
                //     name: 'Despacito',
                //     url: 'https://lx-sycdn.kuwo.cn/b5979ae8092fc177a15a4cef6bf8d180/65b1400f/resource/n3/17/14/4121472792.mp3'
                // },
                {
                    name: 'You Spin Me Round',
                    url: 'https://company-fj.s3.ap-east-1.amazonaws.com/siteadmin%2Fupload%2Fmusic%2FYou+Spin+Me+Round.mp3'
                },
                {
                    name: 'Yesterday',
                    url: 'https://company-fj.s3.ap-east-1.amazonaws.com/siteadmin%2Fupload%2Fmusic%2FYesterday.mp3'
                },
                {
                    name: 'See You Again',
                    url: 'https://company-fj.s3.ap-east-1.amazonaws.com/siteadmin%2Fupload%2Fmusic%2FWiz+Khalifa+-107.+See+You+Again+ft.+Charlie+Puth+%5BOfficial+Video%5D+Furious+7+Soundtrack.mp3'
                },
                {
                    name: 'Without You',
                    url: 'https://company-fj.s3.ap-east-1.amazonaws.com/siteadmin%2Fupload%2Fmusic%2FWithout+You.mp3'
                },
                {
                    name: 'Live It Up',
                    url: 'https://company-fj.s3.ap-east-1.amazonaws.com/siteadmin%2Fupload%2Fmusic%2FWill+Smith-9.Live+It+Up.mp3'
                },
                {
                    name: 'Waiting for Love',
                    url: 'https://company-fj.s3.ap-east-1.amazonaws.com/siteadmin%2Fupload%2Fmusic%2FWaiting+for+Love.mp3'
                },
                {
                    name: 'Wait Wait Wait',
                    url: 'https://company-fj.s3.ap-east-1.amazonaws.com/siteadmin%2Fupload%2Fmusic%2FWait+Wait+Wait.mp3'
                },
            ]
        };
    },
    created() {

    },
    mounted() {
        this.start();
    },
    methods: {
        goMenu(idx) {
            // this.$parent
            this.active_menu = idx;
            if(this.$router.currentRoute.name != "index"){
                this.$router.push({path:"index",params:{
                        nav:idx
                    }})
            }else{
                this.$bus.$emit('send2index', idx);
            }

        },
        // 播放前做的事
        handleBeforePlay(next) {
            // 这里可以做一些事情...
            this.currentAudioName = this.audioList[this.$refs.audioPlayer.currentPlayIndex].name

            next() // 开始播放
        },
        start() {
            Fetch('/user/getUserInfoAPi2').then((r) => {
                this.data = r.data;
            });
        },
        goSupport() {
            this.$notify.success({
                title: "atendimento ao Cliente",
                message: "Equipe ao seu serviço 24 horas por dia, 7 dias por semana"
            })
            this.$router.push('support');
        },
        logout() {
            Fetch('/user/logout').then((r) => {
                //console.log(r);
                if (r.code == 1) {
                    this.$router.go(0);
                    localStorage.removeItem('token')
                }
            });
        },

    }
};
</script>

<style lang="less" scoped>
.other_item {
    width: 80%;
    display: flex;
    margin-bottom: 0.3rem;
}

.icon-wrap {
    height: 0.5rem;
    width: 0.5rem;
    line-height: 0.5em;
    background: rgb(24, 36, 52);
    border-radius: 50%;
    text-align: center;
    font-weight: 700;
    color: #c1c0c0;
    font-size: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.other_text {
    height: 0.5rem;
    line-height: 0.5rem;
    text-indent: 0.2rem;
    color: #777777;
    font-size: 0.28rem;
}

.mp3_player {
    width: 94%;
    background: rgb(24, 36, 52);;
    margin: 0 auto 0.1rem auto;
    //overflow: hidden;
    border-radius: 0.2rem;
    padding: 0.2rem 0.1rem;
    box-sizing: border-box;
}

.game_record {
    width: 94%;
    background:rgb(24, 36, 52);
    margin: 0 auto 0.2rem auto;
    //overflow: hidden;
    border-radius: 0.2rem;
    padding: 0.2rem 0.1rem;
    box-sizing: border-box;
    color: #c7c7c7;
    display: flex;
}

.other_list {
    width: 94%;
    //background: #303030;
    margin: 0 auto 0.2rem auto;
    //overflow: hidden;
    border-radius: 0.2rem;
    padding: 0.2rem 0.1rem;
    box-sizing: border-box;
    color: #c7c7c7;
}

.active_record {
    width: 94%;
    background: rgb(24, 36, 52);
    margin: 0 auto 0.2rem auto;
    border-radius: 0.2rem;
    padding: 0.1rem 0.05rem;
    box-sizing: border-box;
    color: #c7c7c7;
    display: flex;
    flex-wrap: wrap;
}

.active_item {
    width: 48%;
    margin: 0 2% 0.2rem 0%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 0.2rem;
    height: 1.1rem;
    color: #fff;
    font-size: 0.2rem;
    text-indent: 0.1rem;
    line-height: 0.5rem;
}

.acbg1 {
    background-image: url("../assets/images/index/bg01.png");
}

.acbg2 {
    background-image: url("../assets/images/index/bg02.png");
}

.acbg3 {
    background-image: url("../assets/images/index/bg03.png");
}

.acbg4 {
    background-image: url("../assets/images/index/bg04.png");
}

.acbg5 {
    background-image: url("../assets/images/index/bg05.png");

}

.acbg6 {
    background-image: url("../assets/images/index/bg06.png");
}
.acbg7 {
    width: 100%;
    background-image: url("../assets/images/index/bg07.png");
}

.grsvg {
    width: 40%;
    display: flex;
    justify-content: flex-end;
}

.grsvg svg {
    width: 1.2rem;
    height: 0.7rem;
}

.grt {
    width: 60%;
    text-align: center;
}

.music_name {
    text-align: center;
    color: #005DFE;
    margin-top: 0.2rem;
}

.left_nav {
    width: 4rem;
    height: 100%;
    background:rgb(17, 25, 35);
    margin-top: 1.2rem;
}

.bgwrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
    overflow: hidden;
}

.game_type {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0.35rem;
}

.game_item_left.active {
    background: rgb(0, 93, 254);
    color: #fff;;
}

.game_item_left {
    width: 42%;
    margin: 0 2% 0.2rem 5%;
    background: rgb(24, 36, 52);
    border-radius: 0.2rem;
    padding: 0.15rem 0;
    color: #adb6c4;;
}


.game_img {
    width: 0.7rem;
    //height: 0.4rem;
    display: block;
    margin: 0 auto;
}

.game_item_left .game_name {
    text-align: center;
    font-size: 0.22rem!important;
    margin-top: 0.1rem;
}
</style>
