<template>
  <div>
    <header>
      <div
        class="menu_btn"
        @click="showLeftNav"
      >
        <van-icon
          v-show="!$parent.show_left_nav"
          name="ascending"
          class="menu_icon"
        />
        <van-icon
          v-show="$parent.show_left_nav"
          name="descending"
          class="menu_icon"
        />
      </div>
      <div class="top_title">
        <img
          src="../assets/images/logo/logo.png"
          class="logo"
          @click="$router.push('/')"
        >
      </div>
      <div
        v-show="!$parent.user_balance"
        class="login-register"
      >
        <div
          class="login-btn"
          @click="$parent.login()"
        >
          Entrar
        </div>
        <div
          class="register-btn"
          @click="$parent.register()"
        >
          Registro
        </div>
      </div>
      <div
        v-show="$parent.user_balance"
        class="user-balance"
      >

        <div class="user_name"></div>
        <div class="bnumber">
          <img
            class="jinbi"
            src="../assets/images/person/a04.png"
          >
          <div class="balance">
            R${{ $parent.user_balance }}
          </div>
          <van-popover
            v-model="show_popover"
            theme="dark"
            trigger="click"
            :actions="actions"
            placement="left-start"
            @select="fundosAction"
          >
            <template #reference>
              <div class="fund_btn">
                Fundos&nbsp;|&nbsp;<i class="el-icon-caret-bottom fz3" />
              </div>
            </template>
          </van-popover>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
  data(){
    return {
      show_left_nav:false,
      show_popover: false,
      actions: [
        { text: "Depósito", icon: "add-o" },
        { text: "Saque", icon: "idcard" },
      ],
    }
  },
  methods:{
    showLeftNav() {
       this.$parent.show_left_nav = !this.$parent.show_left_nav;
    },
    fundosAction(act)
    {
      if (act.text === "Depósito") {
        this.$router.push("recharge");
      }
      if (act.text === "Saque") {
        this.$router.push("withdraw");
      }
    },
  }
}
</script>

<style scoped lang="less">
@headerHeight: 1.2rem;
@mainColor: #005DFE;
@mainBgColor:#0E131B;
header {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  top: 0;
  padding: 0 1%;
  height: @headerHeight;
  background-color: rgb(17, 25, 35);
  padding-right: 10px;
  z-index: 5;
}
.menu_btn {
  float: left;
  width: 0.66rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu_icon {
  font-size: 0.6rem!important;
  color: rgb(119, 119, 119);
}
.top_title {
  text-align: center;
  font-size: 24px;
  display: flex;
  height: 100%;
  float: left;
  justify-content: center;
  align-items: center;
  margin-left: 0.1rem;
}

.logo {
  height: 1rem;
}
.login-register {
  float: right;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.login-btn {
  padding: 0.15rem 0.3rem;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 0.15rem;
  background-color: @mainColor;
  font-size: 0.23rem;
  margin-right: 0.5rem;
  border: 1px solid @mainColor;
}

.register-btn {
  padding: 0.15rem 0.3rem;
  color: @mainColor;
  border: 1px solid @mainColor;
  display: flex;
  align-items: center;
  border-radius: 0.15rem;
  margin-right: 0.2rem;
  font-size: 0.23rem;
}

.fund_btn {
  padding: 0.15rem 0.25rem;
  color: #fff;
  background: @mainColor;
  display: flex;
  align-items: center;
  border-radius: 0.15rem;
  margin-right: 0.2rem;
  font-size: 0.25rem;
  margin-left: 0.5rem;
}
.jinbi {
    height: 0.5rem;
    margin-top: -0.05rem;
    margin-right: 0.1rem;
}

.bnumber {
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.balance {
    color: #feb705;
    font-size: 0.35rem;
    font-weight: 700;
}

.user-balance {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
</style>